import { useTokensStore } from '@/stores/tokensStore';
import PremiumUpsellModal from '@/components/premium/UpsellModal.vue';
import { MemberStatus } from '@tn/shared';

export const usePremiumCheck = () => {
  const modal = useModal();
  const user = useCurrentUserDetails();
  const tokensStore = useTokensStore();
  const reviewsStore = useReviewsStore();

  const isActivePremium = computed(
    () => user?.memberUser?.membership === MemberStatus.Premium
  );
  const tokensCount = computed(() => tokensStore.validTokens.length);

  const isExSampler = computed(() => {
    return (
      !isActivePremium.value &&
      tokensCount.value < 1 &&
      !reviewsStore?.pendingReviewsAmount
    );
  });

  const lostAccessMidCycle = computed(() => {
    return !isActivePremium.value && tokensCount.value > 0;
  });

  const openUpsellModal = (section: string) => {
    modal.open(PremiumUpsellModal, {
      section,
    });
  };

  return {
    isActivePremium,
    isExSampler,
    lostAccessMidCycle,
    openUpsellModal,
  };
};
